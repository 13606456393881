import { IAdvertiser } from "api/types/_advertiser";
import { IAgency } from "api/types/_agency";
import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import CopyWrapper from "components/Common/CopyWrapper";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import { ADVERTISER_CPC_TYPE, STATUS_ADVERTISER_OPTIONS, TYPE_ADVERTISER_OPTIONS } from "helpers/constans";
import { formatLinkHTTPS, formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Spinner, } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { deleteAdvertiser, getAllAdvertiserAgencies, getAdvertisers as onGetAdvertisers, putAdvertiser } from "../../../store/thunks";
import FormAccount from "./FormAccount";
import FormAdvertiser from "./FormAdvertiser";
import ConfigCPC from "./ConfigCPC";
import { IAdvertiserAgency } from "api/types/_advertiserAgency";

const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';
const MAX_WIDTH_SCREEN = 1800;
const ManagementAdvertiser = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const STATUS_ADVERTISER_OPTIONS_LANG = STATUS_ADVERTISER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const TYPE_ADVERTISER_OPTIONS_LANG = TYPE_ADVERTISER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 50),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        name: withDefault(StringParam, ''),
        status: withDefault(StringParam, ''),
        type: withDefault(StringParam, ''),
        agency_code: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Management;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            advertisers: state.advertisers,
            isAdvertiserSuccess: state.isAdvertiserSuccess,
            isAdvertiserLoading: state.isAdvertiserLoading,
            error: state.error,
        })
    );

    const { advertisers, isAdvertiserLoading } = useSelector(ManagementProperties);

    const [nameSearch, setNameSearch] = useState<string>(query?.name || "");

    const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_ADVERTISER_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.status))[0]);

    const [typeSearch, setTypeSearch] = useState<Option | null>(TYPE_ADVERTISER_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.type))[0]);

    const [advertiserDetail, setAdvertiserDetail] = useState<IAdvertiser | null>(null);

    const [advertiserDetailChangeAccountLogin, setAdvertiserDetailChangeAccountLogin] = useState<IAdvertiser | null>(null);

    const [idDelete, setIdDelete] = useState<number | null>(null);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

    const [advertiserShowCPC, setAdvertiserShowCPC] = useState<any>(null);

    const [listAgency, setListAgency] = useState<IAdvertiserAgency[]>([]);
    const [agencySearch, setAgencySearch] = useState<Option | null>({
        label: listAgency?.find((item) => String(item?.code || '') === String(query?.agency_code || ''))?.name || t('All Agency'),
        value: `${listAgency?.find((item) => String(item?.code || '') === String(query?.agency_code || ''))?.code || ''}`
    });

    useEffect(() => {
        dispatch(onGetAdvertisers(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            name: nameSearch || '',
            status: statusSearch?.value || '',
            type: typeSearch?.value || '',
            agency_code: agencySearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        setQuery({
            name: '',
            status: '',
            type: '',
            agency_code: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        }, "push");
        setNameSearch("");
        setStatusSearch(null);
        setTypeSearch(null);
        setAgencySearch({ label: t('All Agency'), value: '' });
    };


    const handleRefresh = (isClose: boolean = true) => {
        if (isClose) { setAdvertiserDetail((_prev) => null); }
        setQuery({ ...query, time_request: + new Date() }, "push");
    }

    const handleRefreshadvertiserDetailChangeAccountLogin = () => {
        setAdvertiserDetailChangeAccountLogin((_prev) => null);
        resetData();
    }


    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: true,
                sortable: true,
                Cell: (cell: any) => (
                    <>
                        <div style={{ minWidth: '40px' }}>{cell?.value}</div>
                    </>
                ),
            },
            {
                Header: t('Advertiser Name'),
                accessor: "name",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ minWidth: '120px' }}>
                                <CopyWrapper contentCopy={item?.name} >
                                    {item?.name}
                                </CopyWrapper>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Type'),
                accessor: "type",
                filterable: false,
                sortable: false,
                thWidth: 100,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '80px' }}>
                            <span className={`rounded-pill badge bg-${['danger', 'success', 'primary'][cell?.value] || 'secondary'}`}>{TYPE_ADVERTISER_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('CPC'),
                accessor: "cpc",
                filterable: true,
                sortable: false,
                description: t('Cost Per Click'),
                thClass: 'text-center',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;

                    return (
                        <>
                            <div className="text-center" style={{ minWidth: '60px' }}>{formatNumberWithCommas(item?.cpc || 0)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Agency'),
                accessor: "agency",
                filterable: false,
                sortable: false,
                thWidth: 100,
                thClass: 'text-center',
                Cell: (cell: any) => (
                    <>
                        <div className="text-center" style={{ minWidth: '80px' }}>{cell?.value?.agency_name ?? '-'}</div>
                    </>
                ),
            },
            {
                Header: t('Budget'),
                accessor: "budget",
                filterable: false,
                sortable: false,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end" style={{ minWidth: '90px' }}>{formatNumberWithCommas(item?.budget || 0)}</div>
                        </>
                    )
                },
            },
            // {
            //     Header: t('Business Registration Number'),
            //     accessor: "business_registration_number",
            //     filterable: true,
            //     sortable: false,
            //     thClass: 'text-center',
            //     Cell: (cell: any) => {
            //         const item = cell?.row?.original;
            //         return (
            //             <>
            //                 <div className="text-center" style={{ minWidth: '60px' }}>{cell?.value || ''}</div>
            //             </>
            //         )
            //     },
            // },
            // {
            //     Header: t('Contact Name'),
            //     accessor: "contact_name",
            //     filterable: false,
            //     sortable: false,
            //     thWidth: 100,
            //     thClass: 'text-center',
            //     Cell: (cell: any) => (
            //         <>
            //             <div className="text-center" style={{ minWidth: '80px' }}>{cell?.value || '-'}</div>
            //         </>
            //     ),
            // },
            // {
            //     Header: t('Contact Phone'),
            //     accessor: "contact_phone",
            //     filterable: false,
            //     sortable: false,
            //     thWidth: 100,
            //     thClass: 'text-center',
            //     Cell: (cell: any) => (
            //         <>
            //             <div className="text-center" style={{ minWidth: '80px' }}>{cell?.value || '-'}</div>
            //         </>
            //     ),
            // },
            // {
            //     Header: t('Contact Email'),
            //     accessor: "contact_email",
            //     filterable: false,
            //     sortable: false,
            //     thWidth: 100,
            //     thClass: 'text-center',
            //     Cell: (cell: any) => (
            //         <>
            //             <div className="text-center" style={{ minWidth: '80px' }}>{cell?.value || '-'}</div>
            //         </>
            //     ),
            // },

            {
                Header: t('Website'),
                accessor: "website_url",
                filterable: false,
                sortable: false,
                thWidth: 100,
                thClass: 'text-start',
                Cell: (cell: any) => (
                    <>
                        <div className="text-start" style={{ minWidth: '80px' }}>{cell?.value ? <Link className='text-normal' to={formatLinkHTTPS(cell?.value)} target="_blank">
                            {cell?.value}
                        </Link> : <span className="text-muted">-</span>}</div>
                    </>
                ),
            },

            {
                Header: t('Memo'),
                accessor: "memo",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div className="text-start d-flex justify-content-end align-items-center w-100 position-relative" style={{ width: '150px' }}
                            onClick={() => { if (isHavePermissionRole(ROLES_FOR_APP.ADVERTISER_UPDATE, userPermissions)) { setItemUpdateMemo({ id: item?.id, memo: item?.memo || '' }) } }}
                        >
                            <div className="w-100 pe-3 color-scrollbar-os fs-12" style={{
                                whiteSpace: 'pre-line',
                                border: '1px solid #eaeaea',
                                padding: '5px',
                                borderRadius: '5px',
                                minHeight: '30px',
                                maxHeight: '250px',
                                overflowY: 'auto',
                            }}>{item?.memo || ''}</div>
                            {isHavePermissionRole(ROLES_FOR_APP.ADVERTISER_UPDATE, userPermissions) && (
                                <div
                                    className="ms-1 cursor-pointer position-absolute"
                                    style={{ right: '5px' }}
                                >
                                    <i className="ri-pencil-fill text-primary"></i>
                                </div>
                            )}
                        </div>
                    )
                },
            },
            {
                Header: t('Status'),
                accessor: "status",
                filterable: false,
                sortable: false,
                thWidth: 120,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    return (
                        <div className="text-end" style={{ minWidth: '100px' }}>
                            <span className={`rounded-pill badge bg-${['danger', 'success'][cell?.value] || 'secondary'}`}>{STATUS_ADVERTISER_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Created at'),
                accessor: "created_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Action'),
                thClass: 'text-center',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="text-center" style={{ minWidth: '120px' }}>
                            <ul className="list-inline justify-content-center hstack gap-2 mb-0">
                                <TooltipCustom
                                    title={t('Button Detail Advertiser')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-secondary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAdvertiserDetail((_prev) => ({ ...item, is_preview: true }));
                                            }}>
                                            <i className="ri-eye-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                                {isHavePermissionRole(ROLES_FOR_APP.ADVERTISER_UPDATE, userPermissions) && (<TooltipCustom
                                    title={t('Button Config CPC')}
                                    id={`update-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="CPC">
                                        <Link className="btn btn-sm btn-soft-warning d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAdvertiserShowCPC((_prev: any) => (item));
                                            }}>
                                            <i className="ri-shield-star-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                                {isHavePermissionRole(ROLES_FOR_APP.ADVERTISER_CONFIG_ACCOUNT_LOGIN, userPermissions) && (<TooltipCustom
                                    title={t('Button Update Account Login Advertiser')}
                                    id={`update-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-success d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAdvertiserDetailChangeAccountLogin((_prev) => item);
                                            }}>
                                            <i className="ri-user-3-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}

                                {isHavePermissionRole(ROLES_FOR_APP.ADVERTISER_UPDATE, userPermissions) && (<TooltipCustom
                                    title={t('Button Update Advertiser')}
                                    id={`update-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAdvertiserDetail((_prev) => item);
                                            }}>
                                            <i className="ri-pencil-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                                {isHavePermissionRole(ROLES_FOR_APP.ADVERTISER_DELETE, userPermissions) && (<TooltipCustom
                                    title={t('Button Delete Advertiser')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item" title="Remove">
                                        <Link className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                                        >
                                            <i className="ri-delete-bin-5-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                            </ul>
                        </div>
                    );
                },
            },
        ],
        [i18n?.language, userPermissions, JSON.stringify(query), STATUS_ADVERTISER_OPTIONS_LANG, TYPE_ADVERTISER_OPTIONS_LANG]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    // Begin::Delete
    const handleConfirmDelete = (item: IAdvertiser) => {
        setIdDelete((_prev) => item?.id);
    };

    const handleActionDelete = async () => {
        if (!idDelete) { return; };
        try {
            setIsConfirmLoading((_prev) => true);
            const response: any = await deleteAdvertiser(idDelete);
            if (response?.code === 200) {
                dispatch(onGetAdvertisers(query));
                setIsConfirmLoading((_prev) => false);
                setIdDelete((_prev) => null);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setIdDelete((_prev) => null);
    }
    // End::Delete

    // Begin:: update memo
    const [itemUpdateMemo, setItemUpdateMemo] = useState<{ id: number, memo: string } | null>(null);
    const [isLoadingUpdateMemo, setIsLoadingUpdateMemo] = useState<boolean>(false);

    const handleUpdateMemo = async () => {
        try {
            if (!itemUpdateMemo) {
                return;
            }
            setIsLoadingUpdateMemo((_prev) => true);
            const response: any = await putAdvertiser(itemUpdateMemo?.id, { memo: itemUpdateMemo?.memo });
            setIsLoadingUpdateMemo((_prev) => false);
            if (response?.code === 200) {
                setItemUpdateMemo((_prev) => null);
                dispatch(onGetAdvertisers(query));
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoadingUpdateMemo((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    }
    // End:: update memo

    const handleActionRefresh = () => {
        dispatch(onGetAdvertisers(query));
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [resAgency]: any = await Promise.all([getAllAdvertiserAgencies()]);
                const list_a = ([{ name: t('All Agency'), id: '', code: '' } as any]).concat(resAgency?.data || []);
                const agency = list_a?.find((item) => String(item?.code || '') === String(query?.agency_code || '')) || null;
                setListAgency((_prev) => list_a);
                setAgencySearch((_prev) => ({
                    label: agency?.name || t('All Agency'),
                    value: agency?.code || '',
                }));
            } catch (error: any) {
                return error;
            }
        };
        handleCallAllOption();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (agencySearch) {
            setListAgency((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Agency') })));
            (!agencySearch?.value) && setAgencySearch((prev) => ({ label: t('All Agency'), value: '' }));
        }

        document.title = `${t("Advertiser Management")} - ${t("Ads Management")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Advertiser Management')} pageTitle={t('Ads Management')} />
                    {isHavePermissionRole(ROLES_FOR_APP.MANAGEMENT_ADVERTISER, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader className="border-0">
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={advertisers?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                            {isHavePermissionRole(ROLES_FOR_APP.ADVERTISER_CREATE, userPermissions) && (<div className="col-sm-auto">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success add-btn rounded-pill"
                                                        id="create-btn"
                                                        onClick={() => setAdvertiserDetail((_prev) => ({} as IAdvertiser))}
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        {t('Button Create Advertiser')}
                                                    </button>
                                                </div>
                                            </div>)}
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={6} md={4} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Advertiser Name')} isShow={!!nameSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Advertiser Name')}...`}
                                                        value={nameSearch}
                                                        onChange={(e) => setNameSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={4} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Type')} isShow={!!typeSearch?.value}>
                                                    <DropdownStatus
                                                        name="type"
                                                        dataList={TYPE_ADVERTISER_OPTIONS_LANG || []}
                                                        placeholder={`${t("Type")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={typeSearch || null}
                                                        onChangeSelect={(e: any) => setTypeSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Type Advertiser'), value: '' }}
                                                        colors={['danger', 'success', 'primary']}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={4} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Status')} isShow={!!statusSearch?.value}>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_ADVERTISER_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={statusSearch || null}
                                                        onChangeSelect={(e: any) => setStatusSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Status'), value: '' }}
                                                        colors={['danger', 'success']}
                                                    />
                                                </LabelWrapper>
                                            </Col>

                                            <Col sm={12} md={12} xl={5} xxl={5} className="hstack gap-1 justify-content-center justify-content-sm-between justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isAdvertiserLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={advertisers?.list?.length ? advertisers?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={advertisers?.pagination?.total}
                                            customPageCount={Math.ceil(Number(advertisers?.pagination?.total) / Number(advertisers?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isAdvertiserLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            {((windowSize?.width >= MAX_WIDTH_SCREEN)) && (
                                <Offcanvas
                                    fade
                                    scrollable={false}
                                    direction="end"
                                    isOpen={(advertiserDetail !== null)}
                                    id="offcanvasExample"
                                    className='off-canvas-lg'
                                >
                                    <OffcanvasHeader className="bg-light" toggle={() => setAdvertiserDetail((_prev) => null)}>
                                        <h5 className="fs-16 mb-1">{!!advertiserDetail?.id ? (!!advertiserDetail?.is_preview ? t('Button Detail Advertiser') : t('Button Update Advertiser')) : t('Button Create Advertiser')}</h5>
                                    </OffcanvasHeader>
                                    <OffcanvasBody className="color-scrollbar-os">
                                        <FormAdvertiser detail={advertiserDetail} agencies={(listAgency || [])?.filter((item) => !!item?.id)} triggerRefresh={handleRefresh} handleClose={() => setAdvertiserDetail((_prev) => null)} />
                                    </OffcanvasBody>
                                </Offcanvas>
                            )}
                            {(windowSize?.width < MAX_WIDTH_SCREEN) && (<Modal
                                isOpen={advertiserDetail !== null}
                                centered={true}
                                size="md"
                                toggle={() => setAdvertiserDetail((_prev) => null)}
                                keyboard={true}
                            >
                                <ModalHeader toggle={() => setAdvertiserDetail((_prev) => null)}>
                                    {!!advertiserDetail?.id ? (!!advertiserDetail?.is_preview ? t('Button Detail Advertiser') : t('Button Update Advertiser')) : t('Button Create Advertiser')}
                                </ModalHeader>
                                <ModalBody className="">
                                    <FormAdvertiser detail={advertiserDetail} agencies={(listAgency || [])?.filter((item) => !!item?.id)} triggerRefresh={handleRefresh} handleClose={() => setAdvertiserDetail((_prev) => null)} />
                                </ModalBody>
                            </Modal>)}
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
            <Modal
                isOpen={itemUpdateMemo !== null}
                centered={true}
                size="md"
                toggle={() => setItemUpdateMemo((_prev) => null)}
                keyboard={true}
            >
                <ModalHeader toggle={() => setItemUpdateMemo((_prev) => null)}>
                    {t('Button Update Memo')}
                </ModalHeader>
                <ModalBody className="">
                    <Col md={12} lg={12}>
                        <div>
                            <label className="form-label">
                                {t("Memo")}
                                <span className="text-danger"> *</span>
                            </label>
                            <Input
                                type="textarea"
                                rows={3}
                                id="memo"
                                name="memo"
                                autocomplete={false}
                                value={itemUpdateMemo?.memo}
                                placeholder={`${t("Memo")}...`}
                                onChange={(event: any) => {
                                    setItemUpdateMemo((prev: any) => ({ ...prev, memo: event?.target?.value || '' }))
                                }}
                            />
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                            <button
                                className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                color="light"
                                type="button"
                                disabled={isLoadingUpdateMemo}
                                style={{ width: '100px' }}
                                onClick={() => setItemUpdateMemo((_prev) => null)}
                            >
                                {t("Button Close")}
                            </button>
                            <button
                                className="btn btn-primary rounded-pill fs-14"
                                color="success"
                                type="button"
                                disabled={isLoadingUpdateMemo}
                                style={{ width: '150px' }}
                                onClick={() => handleUpdateMemo()}
                            >
                                {isLoadingUpdateMemo ? (
                                    <Spinner size="sm" className="me-2"></Spinner>
                                ) :
                                    <> {t("Button Update")}</>}
                            </button>
                        </div>
                    </Col>
                </ModalBody>
            </Modal>
            <Modal
                isOpen={advertiserDetailChangeAccountLogin !== null}
                centered={true}
                size="md"
                toggle={() => setAdvertiserDetailChangeAccountLogin((_prev) => null)}
                keyboard={true}
            >
                <ModalHeader toggle={() => setAdvertiserDetailChangeAccountLogin((_prev) => null)}>
                    {t('Button Update Account Login Advertiser')}
                </ModalHeader>
                <ModalBody className="">
                    <FormAccount detail={advertiserDetailChangeAccountLogin} triggerRefresh={handleRefreshadvertiserDetailChangeAccountLogin} handleClose={() => setAdvertiserDetailChangeAccountLogin((_prev) => null)} />
                </ModalBody>
            </Modal>
            <Modal isOpen={!!advertiserShowCPC} centered={true} scrollable={true} size="lg">
                <ModalHeader toggle={() => setAdvertiserShowCPC((prev: any) => (prev ? null : prev))}>
                    {t('Button Config CPC')}
                </ModalHeader>
                <ModalBody className="py-3 pt-0 px-4">
                    <div style={{ minHeight: '450px' }}>
                        <Row>
                            <Col md={12} lg={12}>
                                <ConfigCPC
                                    type={ADVERTISER_CPC_TYPE.ADVERTISER}
                                    advertiserId={advertiserShowCPC?.id}
                                    advertiser={advertiserShowCPC}
                                    handleActionRefresh={handleActionRefresh}
                                    handleCloseModal={() => setAdvertiserShowCPC((prev: any) => (null))}
                                />
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
            <ModalConfirm
                header={t('Button Delete Advertiser')}
                isOpen={idDelete !== null}
                isLoading={isConfirmLoading}
                onClose={onCloseConfirmClick}
                onConfirm={handleActionDelete}
            />
        </React.Fragment>
    );
};

export default ManagementAdvertiser;